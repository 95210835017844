import Navbar from '../../components/Navbar/index'
import AboutComp from '../../components/About/index'
import Footer from '../../components/Footer/index'

function About() {
    return (
      <div className="App">
        <Navbar />
        <AboutComp />
        <Footer />
      </div>
    );
  }
  
  export default About;