import Submit from '../../components/Submit/index'
import Navbar from '../../components/Navbar/index'
import Footer from '../../components/Footer/index'

function Main() {
  return (
    <div className="App">
      <Navbar/>
      <div class="container block">
        <div class="row row-center">
          <h1 class="msround display-3 py-4">Page Not Found</h1>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;