import EventList from '../../components/EventList/index'
import Navbar from '../../components/Navbar/index'
import Footer from '../../components/Footer/index'
import FirstVisitModal from './../../components/FirstVisitModal/index';
import { useState, useEffect } from 'react';

function Main() {


  const [viewPopup, setViewPopup] = useState(false);

  //update above to useEffect()
  useEffect(()=>{

    setTimeout(() => {
      let visited = localStorage.getItem("alreadyVisited");
      let maybeLater = localStorage.getItem("maybeLater");
      let maybeLaterTime = new Date(localStorage.getItem("firstVisitTime"));
      
      if(visited == "true" && maybeLater == "false") {
           setViewPopup(false)
           //do not view Popup


          //  TODO: fix
      // if clicked maybe later but is still within 24 hours
      } else if (maybeLater == "true" && Date.now() - maybeLaterTime < 86400000) {
            setViewPopup(false)

      //if over 24 hours later.
      } else if (maybeLater == "true" && Date.now() - maybeLaterTime > 86400000) {
            setViewPopup(true)
            localStorage.setItem("maybeLater", "false");
            //do not view Popup
      } else  {
           //this is the first time
           localStorage.setItem("alreadyVisited", "true");
           localStorage.setItem("firstVisitTime", new Date().toISOString());
           setViewPopup(true)
      }
    }, 2000);
    }, [])



    return (
      <div className="App">
        <Navbar/>
        <EventList />
        <Footer />
        {viewPopup && <FirstVisitModal isOpen={viewPopup} />}
      </div>
    );
  
}

export default Main;