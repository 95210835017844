import Navbar from '../../components/Navbar/index'
import HowTo from '../../components/How-To-Strapi';
import Footer from '../../components/Footer/index'


function HowToStrapi() {
    return (
      <div className="App">
        <Navbar />
        <HowTo />
        <Footer />
      </div>
    );
  }
  
  export default HowToStrapi;