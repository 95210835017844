import Navbar from '../../components/Navbar/index'
import BlogList from '../../components/BlogList/index'
import Footer from '../../components/Footer/index'


function HowToStrapi() {
    return (
      <div className="App">
        <Navbar />
        <BlogList />
        <Footer />
      </div>
    );
  }
  
  export default HowToStrapi;