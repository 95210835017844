import WebPages from "./pages/index"
import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/styles.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactDOM.render(


  <React.StrictMode>
    <WebPages />
  </React.StrictMode>,
 document.getElementById("root")
);