import Submit from '../../components/Submit/index'
import Navbar from '../../components/Navbar/index'
import Footer from '../../components/Footer/index'

function Main() {
  return (
    <div className="App">
      <Navbar/>
      <Submit />
      <Footer />
    </div>
  );
}

export default Main;