import React, { Component } from "react";
import { postForm } from "../../utils/api";

class Submit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      org_name: "",
      org_email: "",
      phone: "",
      description: "",
      submitCode: "",
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.id;

    this.setState({
      [name]: value
    });
  }

  async handleSubmit(event) {

    event.preventDefault();

    const code = await postForm(this.state)
    // console.log("Submit Code: ", code)
    this.setState({ submitCode: code })
  }

  render() {

    if (this.state.submitCode == "200"){
      return (

        <div class="container block">
        <div class="row row-center">
          <h1 class="msround display-3 success-submit">Successfully Submitted!</h1>
        </div>

        </div>
      )
    } else if (this.state.submitCode != "200" && this.state.submitCode != "") {

      return(

        <div class="container block">
        <div class="row row-center">
          <h1 class="msround display-3 success-submit">Error submitting form</h1>
          <p class="text-center">Please email info@keeplearningmc.nz for support!</p>
        </div>

        </div>
      )

    } else {
    return (
      <div class="container block">
        <div class="row row-center">
          <h1 class="msround display-3 py-4">Event Organisers - Request a Login</h1>
        </div>
        <div class="row row-center mb-4">
          <form class="submit" id="submitEvent" onSubmit={this.handleSubmit}>
            <div class="form-group">
              <p class="">
              To submit an event, you need a login so you can access your own admin panel on the website. If you have been given a login already, click <a href="https://keeplearningmc.nz/backend/admin">here</a>.
              <br /><br />
              To receive a login, simply fill out the following fields and once approved, you'll be contacted with login details for you to use. As we only have a part time staff member this may take a day or two. Once you login, you can submit as many events as you wish and update and edit event details as needed.
              </p>
              <p class="more-info">
                  If you are only running one event per year or less, we can
                  upload the event on your behalf - email us all the event
                  details, including event url (or if not listed online, a PDF
                  poster if you have one).   Email us at info@keeplearningmc.nz
              </p>

              <label for="name"><strong>Your Name:</strong></label>
              <input
                required
                type="name"
                class="form-control"
                id="name"
                placeholder="John Doe"
                onChange={this.handleInputChange}
              />

              <label for="org_name"><strong>Organisation / Group / Business / Provider:</strong></label>
              <input
                required
                type="name"
                class="form-control"
                id="org_name"
                placeholder="example org"
                onChange={this.handleInputChange}
              />

              <label for="org_email"><strong>Email:</strong></label>
              <input
                required
                type="email"
                class="form-control"
                id="org_email"
                placeholder="name@example.com"
                onChange={this.handleInputChange}
              />

              {/* <label for="cab"><strong>CAB Community Directory link:</strong> <p class="more-info">(If your organisation or group is not yet listed in the <a href="https://www.cab.org.nz/search/#qa=0&sp=1&ir=0&wc=0&locality=Canterbury&city=Ashburton" target="_blank" ref="noreferrer">Community Directory</a> please email info.midcanterbury@cab.org.nz)</p></label>
              <input
                type="name"
                class="form-control"
                id="cab"
                placeholder="Paste link here"
                onChange={this.handleInputChange}
              /> */}

              <label for="phone"><strong>Contact Number:</strong></label>
              <input
                required
                type="name"
                class="form-control"
                id="phone"
                placeholder="022123456"
                onChange={this.handleInputChange}
              />

              <label for="description">
                <strong>Briefly describe your Organisation / Group / Business / Provider:</strong>
                <p class="more-info">Feel free just to paste the CAB Community Directory link if you are listed there. <br />If you are a community group and not yet listed in the <a href="https://www.cab.org.nz/search/#qa=0&sp=1&ir=0&wc=0&locality=Canterbury&city=Ashburton" target="_blank" ref="noreferrer">Community Directory</a> please email info.midcanterbury@cab.org.nz</p>
              </label>
              <textarea
                required
                class="form-control mb-3"
                id="description"
                rows="10"
                // placeholder="What kind of events will you host?"
                onChange={this.handleInputChange}
              ></textarea>

              {/* <label class="mb-2"><strong>Please select any that apply:</strong></label>
              <br />
              <div class="flex">
                <input type="checkbox" class ="mx-3" id="online" name="online"                 onChange={this.handleInputChange}/>
                <label for="online" className="checkbox-submit">
                  {" "}
                  We currently list our events online (e.g. eventbrite, our own
                  website, Facebook)
                </label>
              </div>
              <br />
              <div class="flex">
                <input type="checkbox" class ="mx-3" id="help_online" name="help_online"                 onChange={this.handleInputChange} />
                <label for="help_online" className="checkbox-submit">
                  
                  We would like help listing our events online, managing
                  registrations & taking payments (We use eventbrite.co.nz and are happy to show you how to use
                this for your organisation)
                </label>
              </div> */}
            </div>
              <br />
              <br />
            <button
              type="submit"
              class="btn msround bg-blu2 btn-rounded klmc-button px-5"
            >
              Submit
            </button>
            <br />
            <br />
            <br />

            <p>
            The Keep Learning website is to support community organisations and all Mid Canterbury residents so it is FREE for groups, 
            businesses, Council and other services to list their events. If you find the website beneficial and would like to support 
            it to continue, feel free to make a donation. Thank you for your support.
            </p>
          </form>
        </div>
      </div>
    );
            }
  }
}

export default Submit;
