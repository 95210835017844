import axios from 'axios'
const API_URL = "https://keeplearningmc.nz/backend"

export const getAllEvents = async () => {
    let responseData
    await axios.get(API_URL + '/events?_sort=start_datetime:ASC')
        .then(response => {
            responseData = response.data;
        })
        .catch(error => console.log('Error fetching all events: ', error));
    return responseData
};

export const getMonthEvents = async (currentDate, endDate) => {
    // YYYY-MM-DD
    let url = API_URL + `/events?start_datetime_lte=${endDate}&end_datetime_gte=${currentDate}&_sort=start_datetime:ASC`
    let responseData
    await axios.get(url)
        .then(response => {
            responseData = response.data;
        })
        .catch(error => console.log('Error fetching events after ' + currentDate + ': ', error));
    return responseData
};

export const getCategories = async () => {
    let url = API_URL + '/categories?_sort=name:ASC'
    let responseData
    await axios.get(url)
        .then(response => {
            responseData = response.data;
        })
        .catch(error => console.log('Error fetching categories' + error));
    return responseData
};

export const getLocations = async () => {
    let url = API_URL + '/locations?_sort=name:ASC'
    let responseData
    await axios.get(url)
        .then(response => {
            responseData = response.data;
        })
        .catch(error => console.log('Error fetching locations' + error));
    return responseData
};


export const getEventsFilter = async (query) => {
    let url = API_URL + query + "&_sort=start_datetime:ASC"
    let responseData
    await axios.get(url)
        .then(response => {
            responseData = response.data;
        })
        .catch(error => console.log('Error fetching locations' + error));
    return responseData
};



export const postEmail = async (email, categories, locations) => {
    await axios.post(API_URL + "/notifications", {
        Email: email,
        locations: locations,
        categories: categories

    })
        .then(response => {
            console.log(response);
        });
};

export const postForm = async (bodyFormData) => {

    let code = null;

    // console.log("got body form data:")
    // console.log(bodyFormData)
    await axios.post(API_URL + "/notifications/submitForm", bodyFormData)
        .then(response => {
            // console.log(response);
            // return status code
            code = response.status
        });

    return code;
};

export const getPostCount = async () => {
    let data
    await axios.get(API_URL + `/blogs/count`).then(response => {
        data = response.data
    }).catch(error => console.log("Could not count total posts, please contact Conrad. " + error))

    return data
}


export const getFunders = async () => {
    let data
    await axios.get(API_URL + `/funders`).then(response => {
        data = response.data
    }).catch(error => console.log("Could not get funders, please contact Conrad. " + error))

    return data
}


export const getPostsPagination = async (start, postsPerPage) => {
    let data
    await axios.get(API_URL + `/blogs?_sort=created_at:desc&_start=${start}&_limit=${postsPerPage}`).then(response => {
        data = response.data
    }).catch(error => console.log("Could not fetch posts with pagination." + error))

    return data
}