import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Main from './Main/index';
import About from './About/index';
import HowTo from './HowTo/index';
import Submit from './Submit/index'
import Blogs from './Blogs/index'
import NotFound from './404/index'
const WebPages = () => {

    return(
        <Router>
            <Route exact path="/" component= {Main} />
            <Route path = "/about" component = {About} />
            <Route path = "/submit" component = {Submit} />
            <Route path = "/HowTo" component = {HowTo} />
            <Route path = "/blogs" component = {Blogs} />
        </Router>
    );
};
export default WebPages;